class USBIPServer {
    constructor() {
        this.devices = {};
        this.nextDevnum = 1;

        this.ondevicebound = null;
        this.ondeviceunbound = null;

        this.processDataPromise = new Promise((resolve) => resolve(true));

        navigator.usb.ondisconnect = event => {
            this._onDeviceDisconnected(event.device);
        }
    }

    async bindDevice() {
        let dev= await navigator.usb.requestDevice(this._getDeviceFilters());

        let device = Object.values(this.devices).find(d => {
            return d.dev.vendorId == dev.vendorId && d.dev.productId == dev.productId
        });

        if (device) {
            // Device already bound
            return;
        }

        device = new Device (1, this.nextDevnum++, dev);

        this.devices[device.busid] = device;

        if (this.ondevicebound) {
            this.ondevicebound(device);
        }
    }

    unbindDevice(device) {
        delete this.devices[device.busid];

        device.close ();

        console.log(`Device ${device.busnum}-${device.devnum} unbound`)

        if (this.ondeviceunbound) {
            this.ondeviceunbound(device);
        }
    }

    _onDeviceDisconnected(usbDevice) {
        for (const busid in this.devices) {
            let device = this.devices[busid];
            if (device.dev === usbDevice) {
                this.unbindDevice(device);
                break;
            }
        }
    }

    processClientData(busid, data) {
        let device = this.devices[busid];

        if (!device) {
            console.warn(`Request from unknown device ${busid}`);
            return;
        }

        device.processClientData(data);
    }

    _getDeviceFilters() {
        let filters = [];

        const USBIP_PROTECTED_CLASSES = [
            USB_AUDIO_CLASS, USB_HID_CLASS, USB_MASS_STORAGE_CLASS,
            USB_SMART_CARD_CLASS, USB_VIDEO_CLASS, USB_AUDIO_VIDEO_CLASS,
            USB_WIRELESS_CLASS
        ];

        for (let classid = 0; classid <= 0xFF; ++classid) {
            if (!USBIP_PROTECTED_CLASSES.includes(classid)) {
                filters.push({classCode: classid});
            }
        }

        return {filters: filters}
    }
}

class Device {
    constructor(busnum, devnum, usbdevice) {
        this.busnum = busnum;
        this.devnum = devnum;
        this.dev = usbdevice;

        this.input = new Uint8Array();
        this.processDataPromise = new Promise((resolve) => resolve(true));

        this.pendingRequests = new Set();

        this.onmessage = null;
        this.onerror = null;
    }

    get busid() {
        return `${this.busnum}-${this.devnum}`;
    }

    get devid() {
        return this.busnum << 16 | this.devnum;
    }

    processClientData(data) {
        this.processDataPromise = this.processDataPromise.then(async () => {
            try {
                let tmp = new Uint8Array(this.input.byteLength + data.length);
                tmp.set(this.input, 0);
                tmp.set(data, this.input.byteLength);
                this.input = tmp;

                while (await this.readOneRequest());
            } catch (error) {
                this.reportError(error);
            }
        });
    }

    async readOneRequest() {
        if (this.input.byteLength < 4) {
            return false;
        }

        let req = new DataView(this.input.buffer);
        let commandCode;

        if (req.getUint16(0) == 0x0111) {
            commandCode = req.getUint16(2);
        } else {
            commandCode = req.getUint32(0);
        }

        switch (commandCode) {
            case OP_REQ_IMPORT:
                return await this.handle_OP_REQ_IMPORT();
            case OP_CMD_SUBMIT:
                return await this.handle_OP_CMD_SUBMIT();
            case OP_CMD_UNLINK:
                return this.handle_OP_CMD_UNLINK();
            default:
                console.log(`Unsupported command 0x${commandCode.toString(16)}`);
        }

        return false;
    }

    async handle_OP_REQ_IMPORT() {
        if (this.input.byteLength < 40) {
            return false;
        }

        let busid = String.fromCharCode(...this.input.slice(8, Math.min(this.input.indexOf(0, 8), 41)));

        console.assert(busid == this.busid, "Unexpected import device ${busid}");

        console.log (`Request to import device ${busid}`);

        this.input = this.input.slice(40);

        let reply;

        try {
            await this.dev.open();

            reply = new Uint8Array(0x13F + 1);

            let buf = new DataView(reply.buffer);
            buf.setUint16(0, 0x0111);
            buf.setUint16(2, OP_REP_IMPORT);
            buf.setUint32(4, 0x00000000);
            writeString(reply, 8,
                `/webusb/${this.busid}`);
            writeString(reply, 0x108, busid);
            buf.setUint32(0x128, this.busnum);
            buf.setUint32(0x12C, this.devnum);
            buf.setUint32(0x130, USB_FULL_SPEED);
            buf.setUint16(0x134, this.dev.vendorId);
            buf.setUint16(0x136, this.dev.productId);
            buf.setUint16(0x138, this.dev.deviceVersionMinor); // bcdDevice
            buf.setUint8(0x13A, this.dev.deviceClass);
            buf.setUint8(0x13B, this.dev.deviceSubclass);
            buf.setUint8(0x13C, this.dev.deviceProtocol);
            buf.setUint8(0x13D, 0); // looks like USBIP server always puts zero here?
            buf.setUint8(0x13E, this.dev.configurations.length);
            buf.setUint8(0x13F, 0); // looks like USBIP server always puts zero here?
        } catch (err) {
            reply = new Uint8Array(8);

            let buf = new DataView(reply.buffer);
            buf.setUint16(0, 0x0111);
            buf.setUint16(2, OP_REP_IMPORT);
            buf.setUint32(4, 0x00000001);

            throw err;
        } finally {
            this.sendMessage(reply);
        }

        return true;
    }

    parseUSBIPHeader(buf) {
        if (buf.byteLength < 0x14) {
            return null;
        }

        let header = {
            seqnum: buf.getUint32(0x04),
            devid: buf.getUint32(0x08),
            direction: buf.getUint32(0x0C),
            endpoint: buf.getUint32(0x10)
        }

        console.assert(header.devid == this.devid,
            `Received request from unexpected device ${header.devid}`);

        console.log(`dev ${this.busid} req ${header.seqnum}`);

        return header;
    }

    async handle_OP_CMD_SUBMIT() {
        let packetLen = 0x30;

        if (this.input.byteLength < packetLen) {
            return false;
        }

        let buf = new DataView(this.input.buffer);

        let header = this.parseUSBIPHeader(buf);
        if (!header) {
            return false;
        }

        let transferFlags = buf.getUint32(0x14);
        let transferBufferLen = buf.getUint32(0x18);
        let startFrame = buf.getUint32(0x1C);
        let numberOfPackets = buf.getUint32(0x20);
        let interval = buf.getUint32(0x24);

        let rawSetup = this.readSlice(0x28, 8);
        let setup = setup2struct (rawSetup);

        let transferBuffer;

        if (header.direction == USBIP_DIR_OUT) {
            packetLen += transferBufferLen;
            if (this.input.byteLength < packetLen) {
                return false;
            }

            transferBuffer = this.readSlice(0x30, transferBufferLen);
        }

        // TODO iso_packet_descriptor

        this.input = this.input.slice(packetLen);

        if (this.pendingRequests.has(header.seqnum)) {
            console.warn(`Duplicate seqnum ${header.seqnum}!`);
            return true;
        }

        this.pendingRequests.add(header.seqnum);

        let transferPromise;

        if (header.direction == USBIP_DIR_IN) {
            if (header.endpoint == 0) {
                transferPromise = this.dev.controlTransferIn(setup.data,
                    transferBufferLen);
            } else {
                transferPromise = this.dev.transferIn(header.endpoint,
                    transferBufferLen);
            }
        } else {
            if (header.endpoint == 0) {
                if (setup.data.request == USB_REQ_SET_CONFIGURATION &&
                    setup.data.requestType == "standard" &&
                    setup.data.recipient == "device") {
                    await this.dev.selectConfiguration(setup.data.value);
                } else if (setup.data.recipient == "interface") {
                    await this.ensureInterfaceIsClaimed(setup.data.index);
                }
                transferPromise = this.dev.controlTransferOut(setup.data,
                    transferBuffer);
            } else {
                transferPromise = this.dev.transferOut(header.endpoint,
                    transferBuffer);
            }
        }

        transferPromise.then(async (usbReply) => {
            if (!this.pendingRequests.has(header.seqnum)) {
                // The request has been cancelled with OP_CMD_UNLINK.
                return;
            }

            let replyLen = 0x30 + (usbReply.data ? usbReply.data.byteLength : 0);
            let actualLen = usbReply.data ? usbReply.data.byteLength : transferBufferLen;

            let reply = new Uint8Array(replyLen);

            buf = new DataView(reply.buffer);
            writeUSBIPHeader(buf, OP_RET_SUBMIT, header.seqnum);
            buf.setUint32(0x14, usbReply.status == "ok" ? 0 : 1);
            buf.setUint32(0x18, actualLen);
            buf.setUint32(0x1C, 0); // not ISO transfer
            buf.setUint32(0x20, 0); // number of ISO packets
            buf.setUint16(0x24, 0); // error count

            if (usbReply.data) {
                reply.set(new Uint8Array(usbReply.data.buffer), 0x30);
            }

            console.log(`REP ${header.seqnum}`);

            this.pendingRequests.delete(header.seqnum);

            this.sendMessage(reply);
        });

        return true;
    }

    handle_OP_CMD_UNLINK() {
        if (this.input.byteLength < 48) {
            return false;
        }

        let buf = new DataView(this.input.buffer);

        let header = this.parseUSBIPHeader(buf);

        this.input = this.input.slice(48);

        let unlinkSeqnum = buf.getUint32(0x14);
        console.log (`UNLINK ${unlinkSeqnum}`);

        let reply = new Uint8Array(48);

        buf = new DataView(reply.buffer);
        writeUSBIPHeader(buf, OP_RET_UNLINK, header.seqnum);
        buf.setUint32(0x14, this.pendingRequests.has(unlinkSeqnum) ? -ECONNRESET : 0);

        this.pendingRequests.delete(unlinkSeqnum);

        console.log(`REP ${header.seqnum}`);

        this.sendMessage(reply);

        return true;
    }

    async ensureInterfaceIsClaimed(interfaceNumber) {
        if (!this.dev.configuration) {
            throw new Error("No configuration set when claiming USB interface");
        }

        let iface = this.dev.configuration.interfaces.find(iface => {
            return iface.interfaceNumber == interfaceNumber;
        })

        if (!iface) {
            throw new Error(`No interface ${interfaceNumber} on device ${this.busid}`);
        }

        if (!iface.claimed) {
            await this.dev.claimInterface(interfaceNumber);
        }
    }

    readSlice(offset, len) {
        return this.input.slice(offset, offset + len);
    }

    sendMessage(msg) {
        if (this.onmessage) {
            this.onmessage(msg);
        }
    }

    reportError(error) {
        console.warn(`${this.busid} USB error: ${error}`);
        if (this.onerror) {
            this.onerror(error);
        }
    }

    close() {
        this.pendingRequests.clear();

        if (this.dev.opened) {
            this.dev.close();
        }
    }
}

const OP_REQ_IMPORT = 0x8003;
const OP_REP_IMPORT = 0x0003;
const OP_REQ_DEVLIST = 0x8005;
const OP_REP_DEVLIST = 0x0005;
const OP_CMD_SUBMIT = 0x00000001;
const OP_RET_SUBMIT = 0x00000003;
const OP_CMD_UNLINK = 0x00000002;
const OP_RET_UNLINK = 0x00000004;
const USBIP_DIR_OUT = 0;
const USBIP_DIR_IN = 1;

const USB_FULL_SPEED = 2;

const USB_REQ_SET_CONFIGURATION = 0x9;
const USB_REQ_SET_INTERFACE = 0xB;

const ECONNRESET = 3426;

const USB_AUDIO_CLASS = 0x01;
const USB_HID_CLASS = 0x03;
const USB_MASS_STORAGE_CLASS = 0x08;
const USB_SMART_CARD_CLASS = 0x0B;
const USB_VIDEO_CLASS = 0x0E;
const USB_AUDIO_VIDEO_CLASS = 0x10;
const USB_WIRELESS_CLASS = 0xE0;

function writeUSBIPHeader(buffer, command, seqnum)
{
    buffer.setUint32(0x0, command);
    buffer.setUint32(0x4, seqnum);
}

function setup2struct(buffer)
{
    let type;
    switch ((buffer[0] >> 5) & 0x03) {
        case 0x0:
            type = "standard";
            break;
        case 0x1:
            type = "class";
            break;
        case 0x2:
            type = "vendor";
            break;
        default:
            throw "Unknown request type"
    }

    let recipient;
    switch (buffer[0] & 0x1F) {
        case 0x00:
            recipient = "device";
            break;
        case 0x01:
            recipient = "interface";
            break;
        default:
            throw "Unknown recipient";
    }

    return {
        direction: buffer[0] >> 7,
        data: {
            requestType: type,
            recipient: recipient,
            request: buffer[1],
            value: (buffer[3] << 8) + buffer[2],
            index: (buffer[5] << 8) + buffer[4]
        }
    }
}

function writeString(array, pos, str)
{
    for (let ch of str) {
        array[pos++] = ch.charCodeAt(0);
    }
}
