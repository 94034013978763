/**
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

//const { query } = require("@angular/animations");

/*eslint no-unused-vars: ["error", { "vars": "local" }]*/


/**
* @typedef {Object} WebRTCDemoSignalling
* @property {function} ondebug - Callback fired when a new debug message is set.
* @property {function} onstatus - Callback fired when a new status message is set.
* @property {function} oninfo - Callback fired when an info occurs.
* @property {function} onerror - Callback fired when an error occurs.
* @property {function} onice - Callback fired when a new ICE candidate is received.
* @property {function} onsdp - Callback fired when SDP is received.
* @property {function} connect - initiate connection to server.
* @property {function} onconnectfailed - Connection failed after retry policy.
* @property {function} disconnect - close connection to server.
*/
class WebRTCDemoSignalling {
    /**
     * Interface to WebRTC demo signalling server.
     * Protocol: https://github.com/centricular/gstwebrtc-demos/blob/master/signalling/Protocol.md
     *
     * @constructor
     * @param {URL} [server]
     *    The URL object of the signalling server to connect to, created with `new URL()`.
     *    Signalling implementation is here:
     *      https://github.com/centricular/gstwebrtc-demos/tree/master/signalling
     * @param {string} [peer_id]
     *    The peer ID established during signalling that the sending peer (server) will connect to.
     *    This can be anything, but must match what the server will attempt to connect to.
     * @param {string} [app_name]
     *    Application name.
     * @param {string} [stk]
     *    Sharing token.
     * @param {string} [authToken]
     *    Authorization token.
     * @param {string} [window_resolution]
     *    Application window resolution. Example 600x600.
     */
    constructor(server, peer_id, app_name, stk, authToken, window_resolution) {
        /**
         * @private
         * @type {URL}
         */
        this._server = server;

        /**
         * @private
         * @type {string}
         */
        this._peer_id = peer_id;
        /**
         * @private
         * @type {string}
         * Client email
         */
        this._client_email = "";

        /**
         * @private
         * @type {string}
         */
        this._app_name = app_name;

        /**
         * @private
         * @type {string}
         * Sharing token
         */
        this._stk = stk;

        /**
         * @private
         * @type {string}
         */
        this._authToken = authToken;

        /**
         * @private
         * @type {string}
         * Window Resolution
         */
        this._window_resolution = window_resolution;

        /**
         * @private
         * @type {number}
         * Resolution scaling ratio
         */
        this._scaling_ratio = 1.0;

        /**
         * @private
         * @type {SocketIO client socket}
         */
        this._sio_conn = null;

        /**
         * @event
         * @type {function}
         */
        this.onstatus = null;

        /**
         * @event
         * @type {function}
         */
        this.oninfo = null;

        /**
         * @event
         * @type {function}
         */
        this.onerror = null;

        /**
         * @type {function}
         */
        this.ondebug = null;

        /**
         * @event
         * @type {function}
         */
        this.onice = null;

        /**
         * @event
         * @type {function}
         */
        this.onsdp = null;

        /**
         * @event
         * @type {function}
         */
        this.ondisconnect = null;

        /**
         * @type {string}
         */
        this.state = 'disconnected';

    }
    /**
     * Sets Client Email
     *
     * @private
     * @param {String} Email
     */
    _setClientEmail(client_email) {
        this._client_email = client_email;
    }

    /**
     * Sets status message.
     *
     * @private
     * @param {String} message
     */
    _setStatus(message) {
        if (this.onstatus !== null) {
            this.onstatus(message);
        }
    }

    /**
     * Sets a debug message.
     * @private
     * @param {String} message
     */
    _setDebug(message) {
        if (this.ondebug !== null) {
            this.ondebug(message);
        }
    }

    /**
     * Sets info message.
     *
     * @private
     * @param {String} message
     */
    _setInfo(message) {
        if (this.oninfo !== null) {
            this.oninfo(message);
        }
    }

    /**
     * Sets error message.
     *
     * @private
     * @param {String} message
     */
    _setError(message) {
        if (this.onerror !== null) {
            this.onerror(message);
        }
    }

    /**
     * Sets SDP
     *
     * @private
     * @param {String} message
     */
    _setSDP(sdp) {
        if (this.onsdp !== null) {
            this.onsdp(sdp);
        }
    }

    /**
     * Sets ICE
     *
     * @private
     * @param {RTCIceCandidate} icecandidate
     */
    _setICE(icecandidate) {
        if (this.onice !== null) {
            this.onice(icecandidate);
        }
    }

    /**
     * Registers with signalling server when istreamer gets connected to the signalling server.
     * Sends the peer id to the signalling server.
     *
     * @private
     */
    _registerWithSioServer = () => {
        if (this.state === 'registered') {
            return;
        }

        if (this._window_resolution !== undefined && this._window_resolution !== '') {
            this._setInfo(`Window resolution detected: ${this._window_resolution}`);
        } else {
            this._setError(`Window resolution not detected`);
        }

        console.log("Sending HELLO to sio server with peerid: ", this._peer_id);
        this._setInfo(`Sending HELLO to sio server with peerid: ${this._peer_id}`);
        this._sio_conn.emit('HELLO', this._peer_id, { windowResolution: this._window_resolution, scalingRatio: this._scaling_ratio });
        this._setStatus(`Registering the user on the server.`);
    }

    /**
     * Fired whenever the signalling websocket is opened.
     * Sends the peer id to the signalling server.
     *
     * @private
     * @event
     */
    _onSioServerOpen() {
        this._setInfo("Signalling websocket is opened.");

        if (this.state === 'connected') {
            return;
        }
        this.state = 'connected';

        // Send HELLO to the Signal Server
        this._registerWithSioServer();
    }

    /**
     * Fired whenever the signalling websocket is connected.
     *
     * @private
     * @event
     */
    _onSioServerConnect() {
        if (this._sio_conn.connected === true) {
            this._setInfo(`Signalling websocket is connected to the socket ID: ${this._sio_conn.id}. Peerid: ${this._peer_id}`);
            this._setStatus(`Signalling is connected with server`);
        } else {
            this._setError(`Signalling websocket is not connected. Peerid: ${this._peer_id}`);
        }
    }

    /**
     * Fired whenever the signalling websocket has:
     * - the low-level connection cannot be established
     * - the connection is denied by the server
     *
     * TO-DO>
     * 1- Handle connection retries
     * 2- Disconnect after several retries (this._setConnectfailed)
     *
     * @private
     * @event
     */
    _onSioServerConnectError(err) {
        if (err) {
            var errorMessage = (err.message !== undefined && err.message !== '') ? `Message: ${err.message}` : 'Message: Empty';
            var errorStack = (err.stack !== undefined && err.stack !== '') ? `Stack: ${err.stack}` : 'Stack: Empty';

            this._setError(`Signalling websocket has problems in the connection. Peerid: ${this._peer_id}. ${errorMessage}. ${errorStack}.`);
        } else {
            this._setError(`Signalling websocket has problems in the connection. Peerid: ${this._peer_id}.`);
        }

        this.state = 'failed';
        this._setStatus(`Could not connect to the server, retrying the connection.`);
    }

    /**
     * Fired whenever the signalling websocket emits and error.
     * Reconnects after 3 seconds.
     *
     * @private
     * @event
     */
    _onSioServerError(err) {
        if (err) {
            var errorMessage = (err.message !== undefined && err.message !== '') ? `Message: ${err.message}` : 'Message: Empty';
            var errorStack = (err.stack !== undefined && err.stack !== '') ? `Stack: ${err.stack}` : 'Stack: Empty';

            this._setError(`Signalling websocket connection error. Peerid: ${this._peer_id}. ${errorMessage}. ${errorStack}.`);
        } else {
            this._setError(`Signalling websocket connection error. Peerid: ${this._peer_id}.`);
        }
    }

    /**
     * Fired whenever a message is received from the signalling server.
     * Message types:
     *   HELLO: response from server indicating peer is registered.
     *   ERROR*: error messages from server.
     *   {"sdp": ...}: JSON SDP message
     *   {"ice": ...}: JSON ICE message
     *
     * @private
     * @event
     * @param {Event} event The event: https://developer.mozilla.org/en-US/docs/Web/API/MessageEvent
     */
    _onSioServerMessage(event) {
        var msg;
        try {
            msg = JSON.parse(event.data);
        } catch (e) {
            if (e instanceof SyntaxError) {
                this._setError("error parsing message as JSON: " + event.data);
            } else {
                this._setError("failed to parse message: " + event.data);
            }
            return;
        }

        if (msg.sdp != null) {
            this._setSDP(new RTCSessionDescription(msg.sdp));
        } else if (msg.ice != null) {
            // Attempt to parse JSON SDP or ICE message
            var icecandidate = new RTCIceCandidate(msg.ice);
            this._setICE(icecandidate);
        } else {
            this._setError("unhandled JSON message: " + msg);
        }
        if (msg.eventType && msg.eventType === "WAIT_FOR_AGENT") {
            this._setClientEmail(msg.email);
            this.state = "registered";
            if(this._client_email.indexOf('demo.itopia.com') === -1) {
              this._setStatus(`Registered the ${this._client_email} user with server. Waiting for agent.`);
            }else {
              this._setStatus(`Registered with server. Waiting for agent.`);
            }
        } else if (msg.eventType && event.eventType === "HELLO") {
            this.state = "registered";
            if(this._client_email.indexOf('demo.itopia.com') === -1) {
              this._setStatus(`Registered the ${this._client_email} user with server. Waiting for video stream.`);

            }else {
              this._setStatus(`Registered with server. Waiting for agent.`);
            }
            this._setStatus("Waiting for video stream.");
            return;
        }
    }

    /**
     * Fired whenever the signalling websocket is closed.
     * Reconnects after 1 second.
     *
     * @private
     * @event
     */
    _onSioServerClose() {
        this._setInfo("Signalling websocket is closed.");

        if (this.state !== 'connecting') {
            this.state = 'disconnected';
            console.log("istreamer closed stream");
            this._setStatus("istreamer closed stream.");

            // Reconnect when disconnected.
            const loopsTimeout = setTimeout(() => {
                this.connect();
                clearTimeout(loopsTimeout);
            }, 1000);
        }
    }

    /**
     * Initiates the connection to the signalling server.
     * After this is called, a series of handshakes occurs between the signalling
     * server and the server (peer) to negotiate ICE candiates and media capabilities.
     */
    connect() {
        this.state = 'connecting';
        this._setStatus("Connecting to server.");

        let query = {
            "connect": this._app_name,
            "x-istreamer-user": 'web',
            'stk': this._stk || ''
        };

        this._sio_conn = io(this._server.origin, {
            path: this._server.pathname,
            host: this._server.host,
            query: query,
            extraHeaders: {
                'Authorization': `Bearer ${this._authToken}`,
                'ngsw-bypass': 'true'
            }
        });
        this._sio_conn.on('open', this._onSioServerOpen.bind(this));
        this._sio_conn.on('connect', this._onSioServerConnect.bind(this));
        this._sio_conn.on('connect_error', this._onSioServerConnectError.bind(this));
        this._sio_conn.on('error', this._onSioServerError.bind(this));
        this._sio_conn.on('message', this._onSioServerMessage.bind(this));
        this._sio_conn.on('close', this._onSioServerClose.bind(this));

    }

    /**
     * Closes connection to signalling server.
     * Triggers onServerClose event.
     */
    disconnect() {
        this._sio_conn.disconnect();
        this._sio_conn.destroy();
        this._sio_conn.close();
    }

    /**
     * Send ICE candidate.
     *
     * @param {RTCIceCandidate} ice
     */
    sendICE(ice) {
        this._setDebug("sending ice candidate: " + JSON.stringify(ice));
        this._sio_conn.emit('ice', JSON.stringify({ 'ice': ice, 'peerId': this._peer_id }));
    }

    /**
     * Send local session description.
     *
     * @param {RTCSessionDescription} sdp
     */
    sendSDP(sdp) {
        this._setDebug("sending local sdp: " + JSON.stringify(sdp));
        this._sio_conn.emit('sdp', JSON.stringify({ 'sdp': sdp, 'peerId': this._peer_id }));
    }

    /**
     * Sets window resolution.
     *
     * @param {String} Window resolution
     */
    setWindowResolution(window_resolution) {
        this._window_resolution = window_resolution;
    }

    /**
     * Sets resolution scaling ratio
     *
     * @param {Number} Scaling ratio
     */
    setScalingRatio(scaling_ratio) {
        // cap scaling ratio at 175%, on Windows, greater values result in double-cursor.
        // TODO: remove once double-cursor issue is resolved.
        this._scaling_ratio = Math.min(1.75, scaling_ratio);
    }
}
